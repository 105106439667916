import { StoreConfig } from '@ngneat/elf';
import { StateStorage, persistState } from '@ngneat/elf-persist-state';
import { debounceTime } from 'rxjs';
import { RepositoryAbstract } from './repository-abstract';

export type StoreStorageConfig = {
  storageKey?: string;
  storage: StateStorage;
  resetClearsStorage?: boolean;
};

export abstract class PersistentRepositoryAbstract<T> extends RepositoryAbstract<T> {
  protected storageConfig: StoreStorageConfig;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(storeConfig: StoreConfig, storeStorageConfig: StoreStorageConfig, ...propsFactories: any) {
    super(storeConfig, ...propsFactories);

    // Si aucune clé n'est fournie, on utilise le nom du store
    if (!storeStorageConfig.storageKey) {
      storeStorageConfig.storageKey = storeConfig.name;
    }

    this.storageConfig = storeStorageConfig;
    const { storageKey, storage } = storeStorageConfig;

    // Persistance dans le SessionStorage
    persistState(this._store, {
      key: storageKey,
      storage,
      // Debounce pour les perfs
      source: () => this._store.pipe(debounceTime(1000)),
    });
  }

  override reset(): void {
    super.reset();

    const { resetClearsStorage, storageKey, storage } = this.storageConfig;
    if (resetClearsStorage && storageKey) {
      storage.removeItem(storageKey);
    }
  }
}
