import { isPlatformBrowser } from '@angular/common';
import { Injectable, PLATFORM_ID, inject } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  // eslint-disable-next-line @typescript-eslint/ban-types
  private readonly platformId: Object = inject(PLATFORM_ID);

  get<T>(key: string): T | null {
    if (this.isBrowser()) {
      const item: string | null = localStorage.getItem(key);
      if (item) {
        try {
          // Normalisation de undefined, transformé en null par défaut
          return item === 'undefined' ? undefined : JSON.parse(item);
        } catch (e) {
          return null;
        }
      }
    }

    return null;
  }

  set<T>(key: string, value: T): void {
    if (this.isBrowser()) {
      // Normalisation de undefined, transformé en null par défaut
      const item: string = value === undefined ? 'undefined' : JSON.stringify(value);
      localStorage.setItem(key, item);
    }
  }

  remove(key: string): void {
    if (this.isBrowser()) {
      localStorage.removeItem(key);
    }
  }

  private isBrowser(): boolean {
    return isPlatformBrowser(this.platformId);
  }
}
