import { Routes } from '@angular/router';
import { FeatureAcl, FeatureAclGuard } from '../../core/acl';

export const mailingFeatureRoutes: Routes = [
  {
    path: 'mailing',
    canActivate: [FeatureAclGuard(FeatureAcl.mailing)],
    children: [
      {
        path: 'send',
        loadComponent: () => import('./pages/mail-send/mail-send.component'),
      },
      {
        path: 'templates',
        loadComponent: () => import('./pages/mail-templates/mail-templates.component'),
      },
    ],
  },
];
