import { jwtDecode } from 'jwt-decode';
import { JwtToken } from '../types/jwt-token.type';

export const getDecodedAccessToken = (token: string): JwtToken | null => {
  try {
    return jwtDecode<JwtToken>(token);
  } catch (error) {
    return null;
  }
};
