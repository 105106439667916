import { inject } from '@angular/core';
import { CanActivateChildFn, CanActivateFn, Router, UrlTree } from '@angular/router';
import { UserRepository } from '../../../features/users/shared/repositories/user.repository';
import { FeatureAclType } from '../types';
import { userHasAccessToFeature } from '../utils';

export const FeatureAclGuard =
  (featureAcl: FeatureAclType): CanActivateFn | CanActivateChildFn =>
  (): true | UrlTree => {
    const user = inject(UserRepository).user;
    return userHasAccessToFeature(user, featureAcl) || inject(Router).parseUrl('not-found');
  };
