import { Routes } from '@angular/router';
import { FeatureAcl, FeatureAclGuard } from '../../core/acl';

export const shopsFeatureRoutes: Routes = [
  {
    path: 'shops',
    canActivate: [FeatureAclGuard(FeatureAcl.shops)],
    children: [
      {
        path: '',
        pathMatch: 'full',
        loadComponent: () => import('./pages/list/list.component'),
      },
      {
        path: 'create',
        loadComponent: () => import('./pages/create/create.component'),
      },
      {
        path: 'create/:companyId',
        loadComponent: () => import('./pages/create/create.component'),
      },
      {
        path: 'edit/:id',
        loadComponent: () => import('./pages/edit/edit.component'),
      },
    ],
  },
];
