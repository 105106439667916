import { registerLocaleData } from '@angular/common';
import { HttpClientModule, provideHttpClient, withInterceptors, withInterceptorsFromDi } from '@angular/common/http';
import localeFr from '@angular/common/locales/fr';
import {
  APP_INITIALIZER,
  ApplicationConfig,
  DEFAULT_CURRENCY_CODE,
  LOCALE_ID,
  importProvidersFrom,
  isDevMode,
} from '@angular/core';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS, MatSnackBar } from '@angular/material/snack-bar';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter, withComponentInputBinding, withRouterConfig } from '@angular/router';
import { enableElfProdMode } from '@ngneat/elf';
import { devTools } from '@ngneat/elf-devtools';
import { routes } from './app.routes';
import './chart-js.config';
import { AuthInterceptor } from './core/interceptors/auth.interceptor';
import { HttpErrorInterceptor } from './core/interceptors/error.interceptor';
import { SnackBarService } from './shared/components/snack-bar/services/snack-bar.service';

registerLocaleData(localeFr);

export const appConfig: ApplicationConfig = {
  providers: [
    // START - Dépendances pour HttpErrorInterceptor
    MatSnackBar,
    SnackBarService,
    // END - HttpErrorInterceptor
    provideHttpClient(withInterceptors([AuthInterceptor, HttpErrorInterceptor]), withInterceptorsFromDi()),
    provideRouter(
      routes,
      // Permet de récupérer les routes params directement via @Input()
      withComponentInputBinding(),
      withRouterConfig({ onSameUrlNavigation: 'reload' }),
    ),
    importProvidersFrom(HttpClientModule),
    provideAnimations(),
    // Elf repository Redux Devtools
    {
      provide: APP_INITIALIZER,
      useFactory: () => (): void => {
        if (!isDevMode()) {
          enableElfProdMode();
        } else {
          devTools({ name: 'YuClubAdmin' });
        }
      },
      multi: true,
    },
    { provide: DEFAULT_CURRENCY_CODE, useValue: 'EUR' },
    { provide: LOCALE_ID, useValue: 'fr-FR' },
    { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 2500 } },
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'outline' } },
  ],
};
