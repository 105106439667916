import { Injectable, Signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { select, withProps } from '@ngneat/elf';
import { Observable, map, shareReplay } from 'rxjs';
import { ROLES_ACL, Role } from '../../../../shared/models/role.model';
import { RepositoryAbstract } from '../../../../shared/states/repository-abstract';
import { CompanyGroupData } from '../../../../shared/types/companies-group.type';
import { CompanyType } from '../../../../shared/types/company-type.type';
import { Offers } from '../../../../shared/types/offers.type';
import { UserRole } from '../../../../shared/types/user-role.type';
import { User } from '../../../../shared/types/user.type';
import { userIsAllowedTo } from '../../../../shared/utils/user-is-allowed-to.utils';

@Injectable({
  providedIn: 'root',
})
export class UserRepository extends RepositoryAbstract<User> {
  private readonly _user$: Observable<User>;
  private readonly _email$: Observable<string | undefined>;
  private readonly _companyUID$: Observable<string | undefined>;
  private readonly _isAdmin: Signal<boolean>;

  constructor() {
    super({ name: 'ycUser' }, withProps<Partial<User> | undefined>({}));

    this._user$ = this._store.pipe(
      select((state: User) => state),
      shareReplay(1),
    );

    this._email$ = this._store.pipe(select((user: User) => user.email));
    this._companyUID$ = this._store.pipe(select((user: User) => user.company?.uid));
    this._isAdmin = toSignal(this._user$.pipe(map(() => this.isAllowedTo(Role.admin))), {
      initialValue: false,
    });
  }

  get user$(): Observable<User> {
    return this._user$;
  }

  get user(): User {
    return this._store.getValue();
  }

  get email$(): Observable<string | undefined> {
    return this._email$;
  }

  get companyUID$(): Observable<string | undefined> {
    return this._companyUID$;
  }

  get isLoggedIn(): boolean {
    return !!this._store.getValue()?.id;
  }

  get isAdmin(): Signal<boolean> {
    return this._isAdmin;
  }

  get rolesAcl(): Role[] {
    if (!this.user?.roles?.length) {
      return ROLES_ACL[Role.forbidden];
    }

    // TODO : check si l'utilisateur peut réellement avoir plusieurs droits
    const role: UserRole = this.user?.roles?.[0] || { role: Role.forbidden };
    return ROLES_ACL[role.role as Role];
  }

  isAllowedTo(role: Role | Role[]): boolean {
    return userIsAllowedTo(this.user, role);
  }

  get companyGroupData(): CompanyGroupData {
    const { company } = this.getValue();
    return {
      id: company.id || '',
      name: company.name || '',
      type: company.type || CompanyType.SINGLE,
      offerType: company.offer?.type || Offers.NONE,
    };
  }

  get companyOfferType(): Offers {
    return this.getValue().company.offer?.type ?? Offers.NONE;
  }

  get noSubscription(): boolean {
    return this._store.getValue().company.noSubscription ?? false;
  }

  get isActingForParentCompany(): boolean {
    return this.getValue().company.type === CompanyType.PARENT;
  }

  get isActingForChildCompany(): boolean {
    return this.getValue().company.type === CompanyType.CHILD;
  }

  get isActingForSingleCompany(): boolean {
    return this.getValue().company.type === CompanyType.SINGLE;
  }

  get isCompanyAdmin(): boolean {
    const user: User = this.getValue();
    return (
      (this.isActingForSingleCompany && userIsAllowedTo(user, Role.admin)) ||
      (this.isActingForParentCompany && userIsAllowedTo(user, Role.superadmin)) ||
      (this.isActingForChildCompany && userIsAllowedTo(user, Role.admin))
    );
  }

  get isSingleCompanyAdminOrSuperadmin(): boolean {
    const user: User = this.getValue();
    return (
      (this.isActingForSingleCompany && userIsAllowedTo(user, Role.admin)) ||
      (this.isActingForParentCompany && userIsAllowedTo(user, Role.superadmin))
    );
  }

  get isCorporationAdmin(): boolean {
    const user: User = this.getValue();
    return (
      (this.isActingForParentCompany && userIsAllowedTo(user, Role.superadmin)) ||
      (this.isActingForChildCompany && userIsAllowedTo(user, Role.admin))
    );
  }

  get companyOfferCardType(): string {
    return `carte ${this.companyOfferType === Offers.GIFT_CARD ? 'cadeau' : 'de fidélité'}`;
  }

  /**
   * Récupération du nom de l'application, potentiellement surchargé par l'enseigne parente
   */
  get appName(): string {
    const { parentCompany, name, appName } = this.getValue().company;
    return parentCompany?.appName ?? appName ?? parentCompany?.name ?? name;
  }
}
