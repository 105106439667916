import { Injectable } from '@angular/core';
import { select, withProps } from '@ngneat/elf';
import { localStorageStrategy } from '@ngneat/elf-persist-state';
import { Observable } from 'rxjs';
import { PersistentRepositoryAbstract } from '../../shared/states/persistent-repository-abstract';
import { Auth, YC_AUTH_DEFAULT_STATE } from '../../shared/types/auth.type';

@Injectable({
  providedIn: 'root',
})
export class AuthRepository extends PersistentRepositoryAbstract<Auth> {
  private readonly _auth$: Observable<Auth>;

  constructor() {
    // Création du repository
    super(
      { name: 'ycAuth' },
      {
        storage: localStorageStrategy,
        resetClearsStorage: true,
      },
      withProps<Auth>(YC_AUTH_DEFAULT_STATE),
    );

    this._auth$ = this._store.pipe(select((state: Auth) => state));
  }

  get auth$(): Observable<Auth> {
    return this._auth$;
  }

  get auth(): Auth {
    return this._store.getValue();
  }
}
