import { User } from '../../../shared/types/user.type';
import { userHasRole } from '../../../shared/utils/user-has-role.utils';
import { userIsAllowedTo } from '../../../shared/utils/user-is-allowed-to.utils';
import { FEATURES_ALLOWED_ACL, FEATURES_FORBIDDEN_ACL } from '../constants';
import { FeatureAclType } from '../types';

export const userHasAccessToFeature = (user: User | undefined, feature: FeatureAclType): boolean =>
  // Si l'utilisateur a le rôle minimal requis pour accéder à l'item
  userIsAllowedTo(user, FEATURES_ALLOWED_ACL[feature]) &&
  // Et que son rôle n'est pas blacklisté sur cette feature
  !userHasRole(user, FEATURES_FORBIDDEN_ACL[feature]);
