import { ROLES_GROUP_YUZUCOM } from '../../../shared/constants/roles-groups.constant';
import { Role } from '../../../shared/models/role.model';
import { FeatureAclType } from '../types';
import { FEATURES_FORBIDDEN_ACL } from './features-forbidden-acl.constant';

/**
 * ACL des fonctionnalités masquées dans le menu selon le rôle de l'utilisateur
 * Rôles MINIMAUX !! (pas de rôles stricts)
 */
export const FEATURES_MENU_FORBIDDEN_ACL: Record<FeatureAclType, Role[]> = {
  cards: [...FEATURES_FORBIDDEN_ACL.cards],
  cardGenerate: [...FEATURES_FORBIDDEN_ACL.cardGenerate],
  cardView: [...FEATURES_FORBIDDEN_ACL.cardView],
  companies: [...FEATURES_FORBIDDEN_ACL.companies],
  // On masque le lien du menu vers la page de détail de l'Enseigne pour les rôles Yuzu*
  company: [...FEATURES_FORBIDDEN_ACL.company, ...ROLES_GROUP_YUZUCOM, Role.superadmin],
  companyStripeAcct: [...FEATURES_FORBIDDEN_ACL.companyStripeAcct],
  shops: [...FEATURES_FORBIDDEN_ACL.shops],
  managers: [...FEATURES_FORBIDDEN_ACL.managers],
  customers: [...FEATURES_FORBIDDEN_ACL.customers],
  invoices: [...FEATURES_FORBIDDEN_ACL.invoices],
  users: [...FEATURES_FORBIDDEN_ACL.users],
  userCreate: [...FEATURES_FORBIDDEN_ACL.userCreate],
  stats: [...FEATURES_FORBIDDEN_ACL.stats],
  dashboard: [...FEATURES_FORBIDDEN_ACL.dashboard],
  subscriptions: [...FEATURES_FORBIDDEN_ACL.subscriptions],
  // On masque le lien du menu vers la page de détail de l'Abonnement pour les rôles Yuzu*
  subscription: [...FEATURES_FORBIDDEN_ACL.subscription, ...ROLES_GROUP_YUZUCOM],
  subscriptionCreate: [...FEATURES_FORBIDDEN_ACL.subscriptionCreate],
  subscriptionRenew: [...FEATURES_FORBIDDEN_ACL.subscriptionRenew],
  mailing: [...FEATURES_FORBIDDEN_ACL.mailing],
  debug: [...FEATURES_FORBIDDEN_ACL.debug],
  // ACL spécifiques aux offres
  distributedManualAmountReports: [...FEATURES_FORBIDDEN_ACL.distributedManualAmountReports],
};
