import { Routes } from '@angular/router';
import { FeatureAcl } from '../../core/acl';
import { FeatureAclGuard } from '../../core/acl/guards';

export const customersFeatureRoutes: Routes = [
  {
    path: 'customers',
    canActivate: [FeatureAclGuard(FeatureAcl.customers)],
    children: [
      {
        path: '',
        pathMatch: 'full',
        loadComponent: () => import('./pages/list/list.component'),
      },
      {
        path: 'view/:id',
        loadComponent: () => import('../../shared/pages/user-view/view.component'),
      },
      {
        path: 'edit/:id',
        loadComponent: () => import('../../shared/pages/user-edit/edit.component'),
      },
    ],
  },
];
