import { inject, isDevMode } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateChildFn, CanActivateFn, Router, UrlTree } from '@angular/router';
import { environment } from '../../../environments/environment';
import { LocalStorageService } from '../services/local-storage.service';

// TODO : à déplacer dans la lib commune
export const WipGuard: CanActivateFn | CanActivateChildFn = (route: ActivatedRouteSnapshot): boolean | UrlTree => {
  if (!isDevMode()) {
    return true;
  }

  const router: Router = inject(Router);
  const localStorageService: LocalStorageService = inject(LocalStorageService);
  const testToken: string | null = route.paramMap.get('testToken');

  if (testToken && testToken === environment.testToken) {
    localStorageService.set('testToken', testToken);
    return router.parseUrl('/');
  }

  if (localStorageService.get('testToken') === environment.testToken) {
    return true;
  }

  return router.parseUrl('/not-found');
};
