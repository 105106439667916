import { isDevMode } from '@angular/core';
import { Route, Routes } from '@angular/router';
import { WipGuard } from './core/guards/wip.guard';
import { authFeatureRoutes, authTestTokenRoute } from './features/auth/auth.routes';
import { AuthGuard } from './features/auth/shared/guards/auth.guard';
import { cardsFeatureRoutes } from './features/cards/cards.routes';
import { cguFeatureRoutes } from './features/cgu/cgu.routes';
import { companiesFeatureCguProtectedRoutes, companiesFeatureRoutes } from './features/companies/companies.routes';
import { customersFeatureRoutes } from './features/customers/customers.routes';
import { dashboardFeatureRoutes } from './features/dashboard/dashboard.routes';
import { debugFeatureRoutes } from './features/debug/debug.routes';
import { initCguProtectedFeatureRoutes, initFeatureRoutes } from './features/init/init.routes';
import { CguAcknowledgementGuard } from './features/init/shared/guards/cgu-acknowledgement.guard';
import { invoicesFeatureRoutes } from './features/invoices/invoices.routes';
import { mailingFeatureRoutes } from './features/mailing/mailing.routes';
import { managersFeatureRoutes } from './features/managers/managers.routes';
import {
  offersCguProtectedRoutes,
  offersProtectedRoutes,
  offersPublicRoutes,
  offersValidStripeCustomerRoutes,
  offersValidSubscriptionProtectedRoutes,
} from './features/offers/offers.routes';
import { settingsFeatureRoutes } from './features/settings/settings.routes';
import { shopsFeatureRoutes } from './features/shops/shops.routes';
import { statsFeatureRoutes } from './features/stats/stats.routes';
import { stripeFeatureCguProtectedRoutes } from './features/stripe/stripe.routes';
import { ValidSubscriptionGuard } from './features/subscriptions/shared/guards/valid-subscription.guard';
import {
  subscriptionsFeatureCguProtectedRoutes,
  subscriptionsFeatureRoutes,
  subscriptionsFeatureValidStripeCustomerRoutes,
} from './features/subscriptions/subscriptions.routes';
import { ValidCustomerAccountGuard } from './features/users/shared/guards';
import { ValidChildCompanyStripeAccountGuard } from './features/users/shared/guards/valid-child-company-stripe-account.guard';
import { usersFeatureRoutes } from './features/users/users.routes';

const publicRoutes: Routes = [...authFeatureRoutes, ...offersPublicRoutes];
const protectedRoutes: Routes = [...initFeatureRoutes, ...offersProtectedRoutes];

const cguProtectedRoutes: Routes = [
  ...authFeatureRoutes,
  ...companiesFeatureCguProtectedRoutes,
  ...initCguProtectedFeatureRoutes,
  ...subscriptionsFeatureCguProtectedRoutes,
  ...offersCguProtectedRoutes,
  ...stripeFeatureCguProtectedRoutes,
];
const validStripeCustomerRoutes: Routes = [
  ...subscriptionsFeatureValidStripeCustomerRoutes,
  ...offersValidStripeCustomerRoutes,
];

const validSubscriptionProtectedRoutes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'dashboard',
  },
  ...invoicesFeatureRoutes,
  ...cardsFeatureRoutes,
  ...cguFeatureRoutes,
  ...companiesFeatureRoutes,
  ...dashboardFeatureRoutes,
  ...managersFeatureRoutes,
  ...settingsFeatureRoutes,
  ...shopsFeatureRoutes,
  ...statsFeatureRoutes,
  ...subscriptionsFeatureRoutes,
  ...usersFeatureRoutes,
  ...customersFeatureRoutes,
  ...offersValidSubscriptionProtectedRoutes,
  ...mailingFeatureRoutes,
  ...(isDevMode() ? debugFeatureRoutes : []),
];

const notFoundRoute: Route = {
  path: '**',
  loadComponent: () => import('./features/not-found/not-found.component'),
};

export const routes: Routes = [
  {
    path: '',
    // Routes protégées par WipGuard
    canActivate: [WipGuard],
    children: [
      ...publicRoutes,
      {
        path: '',
        canActivate: [AuthGuard],
        children: [
          ...protectedRoutes,
          {
            path: '',
            canActivate: [CguAcknowledgementGuard],
            children: [
              ...cguProtectedRoutes,
              {
                path: '',
                // Attention, cette route vide avec Guard est volontaire, en cas de refactorisation,
                // bien vérifier l'onboarding du gérant via la succession des Guards
                canActivate: [ValidCustomerAccountGuard, ValidChildCompanyStripeAccountGuard],
                children: [
                  ...validStripeCustomerRoutes,
                  {
                    path: '',
                    // Application de ValidSubscriptionGuard APRES ValidCustomerAccountGuard
                    // On veut s'assurer que les informations de facturation sont bien saisies avant de souscrire à l'abonnement
                    canActivate: [ValidSubscriptionGuard],
                    loadComponent: () => import('./shared/layouts/app-layout/app-layout.component'),
                    children: [...validSubscriptionProtectedRoutes],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  // Route publique d'authentification par testToken
  authTestTokenRoute,
  // Doit absolument être la DERNIERE
  notFoundRoute,
];
