import { Routes } from '@angular/router';
import { FeatureAcl, FeatureAclGuard } from '../../core/acl';
import { CompanyGuard } from './shared/guards/company.guard';

export const companiesFeatureCguProtectedRoutes: Routes = [
  {
    path: 'company/init',
    canActivate: [FeatureAclGuard(FeatureAcl.companyStripeAcct)],
    children: [
      {
        path: 'stripe',
        loadComponent: () => import('./pages/init/stripe-acct/stripe-acct.component'),
      },
    ],
  },
];

export const companiesFeatureRoutes: Routes = [
  // Cas particulier : permettre au gérant d'une Enseigne d'y accéder
  {
    path: 'company',
    pathMatch: 'full',
    // Route virtuelle, CompanyGuard va rediriger le gérant vers la page d'édition de son enseigne
    canActivate: [FeatureAclGuard(FeatureAcl.company), CompanyGuard],
    children: [], // Nécessaire à Angular pour que la route soit considérée comme valide
  },
  {
    path: 'companies',
    children: [
      {
        path: '',
        pathMatch: 'full',
        canActivate: [CompanyGuard, FeatureAclGuard(FeatureAcl.companies)],
        loadComponent: () => import('./pages/list/list.component'),
      },
      {
        path: 'create',
        canActivate: [FeatureAclGuard(FeatureAcl.companies)],
        loadComponent: () => import('./pages/create/create.component'),
      },
      {
        path: 'edit/:id',
        canActivate: [FeatureAclGuard(FeatureAcl.company)],
        loadComponent: () => import('./pages/edit/edit.component'),
      },
    ],
  },
];
