import { Routes } from '@angular/router';

export const cguFeatureRoutes: Routes = [
  {
    path: 'cgu',
    children: [
      {
        path: '',
        pathMatch: 'full',
        loadComponent: () => import('./pages/cgu/cgu.component'),
      },
    ],
  },
];
