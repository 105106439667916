/**
 * Liste des fonctionnalités (pages, routes..) de l'application
 */
export enum FeatureAcl {
  cards = 'cards',
  cardGenerate = 'cardGenerate',
  cardView = 'cardView',
  companyStripeAcct = 'companyStripeAcct',
  dashboard = 'dashboard',
  companies = 'companies',
  company = 'company',
  customers = 'customers',
  invoices = 'invoices',
  managers = 'managers',
  users = 'users',
  userCreate = 'userCreate',
  shops = 'shops',
  stats = 'stats',
  subscriptions = 'subscriptions',
  subscription = 'subscription',
  subscriptionCreate = 'subscriptionCreate',
  subscriptionRenew = 'subscriptionRenew',
  mailing = 'mailing',
  debug = 'debug',
  // ACL spécifiques aux offres
  distributedManualAmountReports = 'distributedManualAmountReports',
}

export type FeatureAclType = keyof typeof FeatureAcl;
