import { inject } from '@angular/core';
import { CanActivateFn, Router, UrlTree } from '@angular/router';
import { FeatureAcl, userHasAccessToFeature } from '../../../../core/acl';
import { UserRepository } from '../../../users/shared/repositories/user.repository';

export const CompanyGuard: CanActivateFn = (): boolean | UrlTree => {
  const userRepository: UserRepository = inject(UserRepository);

  if (userHasAccessToFeature(userRepository.user, FeatureAcl.companies)) {
    return true;
  }

  const companyId = userRepository.user?.company?.id;
  const redirectTo = companyId ? `/companies/edit/${companyId}` : '/';
  return inject(Router).parseUrl(redirectTo);
};
