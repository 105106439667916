import { HttpHandlerFn, HttpInterceptorFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { environment } from '../../../environments/environment';
import { AuthRepository } from '../states/auth.repository';

export const AuthInterceptor: HttpInterceptorFn = (request: HttpRequest<unknown>, next: HttpHandlerFn) => {
  // On traite uniquement les requêtes vers l'API
  if (request.url.startsWith(environment.apiUrl)) {
    const accessToken = inject(AuthRepository).auth.accessToken;

    if (accessToken) {
      const authRequest = request.clone({
        setHeaders: { Authorization: `Bearer ${accessToken}` },
      });

      return next(authRequest);
    }
  }

  return next(request);
};
