import { Routes } from '@angular/router';
import { FeatureAcl, FeatureAclGuard } from '../../core/acl';
import { SubscriptionGuard } from './shared/guards/subscription.guard';

export const subscriptionsFeatureCguProtectedRoutes: Routes = [
  {
    path: 'subscriptions/create',
    canActivate: [FeatureAclGuard(FeatureAcl.subscriptionCreate)],
    children: [
      {
        path: 'customer',
        loadComponent: () => import('./pages/create/customer/customer.component'),
      },
    ],
  },
  {
    path: 'subscriptions/unavailable',
    // Pas de Guard, on veut que les utilisateurs sans abonnement puissent accéder à cette page
    loadComponent: () => import('./pages/unavailable/unavailable.component'),
  },
];

export const subscriptionsFeatureValidStripeCustomerRoutes: Routes = [
  {
    path: 'subscriptions/renew',
    canActivate: [FeatureAclGuard(FeatureAcl.subscriptionRenew)],
    loadComponent: () => import('./pages/renew/renew.component'),
  },
];

export const subscriptionsFeatureRoutes: Routes = [
  // Cas particulier : permettre au gérant d'accéder à son abonnement
  {
    path: 'subscriptions',
    children: [
      {
        path: '',
        pathMatch: 'full',
        // Route virtuelle, SubscriptionGuard va rediriger le gérant vers la page d'édition de son abonnement
        canActivate: [FeatureAclGuard(FeatureAcl.subscription), SubscriptionGuard],
        children: [], // Nécessaire à Angular pour que la route soit considérée comme valide
      },
      {
        path: 'view/:id',
        canActivate: [FeatureAclGuard(FeatureAcl.subscription)],
        loadComponent: () => import('./pages/view/view.component'),
      },
    ],
  },
];
