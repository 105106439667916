import { inject } from '@angular/core';
import { CanActivateChildFn, CanActivateFn, Router, UrlTree } from '@angular/router';
import { Observable, map, of } from 'rxjs';
import { Role } from '../../../../shared/models/role.model';
import { userHasRole } from '../../../../shared/utils/user-has-role.utils';
import { UserRepository } from '../../../users/shared/repositories/user.repository';
import { SubscriptionsService } from '../services/subscriptions.service';

export const ValidSubscriptionGuard: CanActivateFn | CanActivateChildFn = (): Observable<boolean | UrlTree> => {
  const router: Router = inject(Router);
  const userRepository: UserRepository = inject(UserRepository);

  // Pas de contrôle pour les utilisateurs Yuzu
  if (userRepository.isAllowedTo(Role.yuzucom)) {
    return of(true);
  }

  return inject(SubscriptionsService)
    .checkCompanySubscription(
      // Création automatique de l'abonnement pour le gérant de super-enseigne
      (userRepository.isActingForParentCompany && userHasRole(userRepository.user, Role.superadmin)) ||
        // Création automatique de l'abonnement pour le gérant d'enseigne indépendante, si nécessaire
        (userRepository.isActingForSingleCompany && userHasRole(userRepository.user, Role.admin)),
    )
    .pipe(map((res: boolean) => res || router.parseUrl('/subscriptions/unavailable')));
};
