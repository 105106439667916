export enum Offers {
  DISTRIBUTED_MANUAL_AMOUNT = 'DISTRIBUTED_MANUAL_AMOUNT',
  MANUAL_AMOUNT = 'MANUAL_AMOUNT',
  CASHBACK = 'CASHBACK',
  GIFT_CARD = 'GIFT_CARD',
  // STAMP = 'STAMP',
  // Offre interne, uniquement pour Yuzu
  NONE = 'NONE',
}

export type Offer = keyof typeof Offers;

export type OffersLabel = { value: Offer; label: string };

export const OFFERS_LABEL = {
  [Offers.DISTRIBUTED_MANUAL_AMOUNT]: 'Montant manuel distribué (Henri Michel)',
  [Offers.MANUAL_AMOUNT]: 'Montant manuel',
  [Offers.CASHBACK]: 'Cashback',
  [Offers.GIFT_CARD]: 'Carte cadeau',
  // [Offers.STAMP]: 'Tampon',
  [Offers.NONE]: 'Aucune (usage interne)',
} as const;
