import { Route, Routes } from '@angular/router';
import { WipGuard } from '../../core/guards/wip.guard';

export const authTestTokenRoute: Route = {
  // TODO : à supprimer
  // http://localhost:4201/auth/testToken/0a724fe2-bdb5-4b33-9423-02d314ca6954
  path: 'auth/testToken/:testToken',
  canActivate: [WipGuard], // La Guard va récupérer le token, le stocker puis gérer la redirection
  children: [], // Nécessaire à Angular pour que la route soit considérée comme valide
};

export const authFeatureRoutes: Routes = [
  {
    // TODO : à supprimer
    path: 'init',
    canActivate: [WipGuard],
    loadComponent: () => import('./pages/init/init.component'),
  },
  {
    path: 'login',
    loadComponent: () => import('./pages/login/login.component'),
  },
  {
    path: 'logout',
    loadComponent: () => import('./pages/logout/logout.component'),
  },
  {
    path: 'reset-password',
    loadComponent: () => import('./pages/reset-password/reset-password.component'),
  },
  {
    path: 'reset-password/:token',
    loadComponent: () => import('./pages/reset-password-token/reset-password-token.component'),
  },
];
