import { inject } from '@angular/core';
import { CanActivateFn, Router, UrlTree } from '@angular/router';
import { Observable, map, of, switchMap, tap } from 'rxjs';
import { AuthService } from '../../../../core/services/auth.service';
import { AuthRepository } from '../../../../core/states/auth.repository';
import { Auth } from '../../../../shared/types/auth.type';
import { User } from '../../../../shared/types/user.type';

export const AuthGuard: CanActivateFn = (): Observable<boolean | UrlTree> => {
  const authService: AuthService = inject(AuthService);
  const router: Router = inject(Router);

  return inject(AuthRepository).auth$.pipe(
    switchMap((auth: Auth) => {
      if (auth.accessToken) {
        return authService.refreshUser().pipe(
          map((res: User | null) => (!!res && res.id ? true : false)),
          tap((isAuth: boolean) => {
            if (!isAuth) {
              authService.logout();
            }
          }),
        );
      }

      return of(router.parseUrl('/login'));
    }),
  );
};
