// Pattern const..as const pour avoir de vraies valeurs en string et non converties
// en integer, et ainsi conserver un minimum d'obfuscation sur l'état de validité
// du compte et limiter les hacks obvious côté Front
export const CustomerAccountStatus = {
  // Compte client conforme (ValiD)
  valid: 'VD',
  // Erreur : pas de compte Stripe associé (NO Stripe ACCount)
  noStripeAccount: 'NOSACC',
  // Erreur : compte client Stripe non conforme
  error: 'ERR',
} as const;

// type CustomerAccountStatusType = "VD" | "NOSACC" | "ERR"
export type CustomerAccountStatusType = (typeof CustomerAccountStatus)[keyof typeof CustomerAccountStatus];
