import { Role } from '../../../shared/models/role.model';
import { FeatureAclType } from '../types';

/**
 * ACL des fonctionnalités autorisées selon le rôle de l'utilisateur
 * Rôles MINIMAUX !! (pas de rôles stricts)
 */
export const FEATURES_ALLOWED_ACL: Record<FeatureAclType, Role[]> = {
  cards: [Role.seller],
  cardGenerate: [Role.superadmin],
  cardView: [Role.seller],
  companies: [Role.superadmin],
  company: [Role.admin], // Utilisé pour que le gérant accède à son enseigne
  companyStripeAcct: [Role.admin], // Utilisé pour que le gérant accède à la page de redirection Stripe
  invoices: [Role.admin],
  shops: [Role.manager],
  managers: [Role.manager],
  customers: [Role.seller],
  users: [Role.seller],
  userCreate: [Role.manager],
  stats: [Role.manager],
  dashboard: [Role.seller],
  subscriptions: [Role.yuzucom],
  subscription: [Role.admin], // Utilisé pour que le gérant accède à son abonnement
  subscriptionCreate: [Role.admin],
  subscriptionRenew: [Role.admin],
  mailing: [Role.manager],
  debug: [Role.yuzudev], // Customer pour pouvoir y accéder quel que soit le user connecté
  // ACL spécifiques aux offres
  distributedManualAmountReports: [Role.admin],
};
