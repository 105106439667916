import { Routes } from '@angular/router';

export const initFeatureRoutes: Routes = [
  {
    path: 'init',
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'cgu',
      },
      {
        path: 'cgu',
        loadComponent: () => import('./pages/cgu/cgu.component'),
      },
    ],
  },
];

export const initCguProtectedFeatureRoutes: Routes = [
  {
    path: 'init/account-error',
    loadComponent: () => import('./pages/account-error/account-error.component'),
  },
];
