import { Store, StoreConfig, StoreDef, createStore } from '@ngneat/elf';

export abstract class RepositoryAbstract<T> {
  protected _store: Store<StoreDef<T>, T>;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(storeConfig: StoreConfig, ...propsFactories: any) {
    this._store = createStore(storeConfig, ...propsFactories) as Store<StoreDef<T>, T>;
  }

  getValue(): T {
    return this._store.getValue();
  }

  update(args: Partial<T>): void {
    this._store.update((state: T) => ({
      ...state,
      ...args,
    }));
  }

  reset(): void {
    this._store.reset();
  }
}
