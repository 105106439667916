import { HttpParams } from '@angular/common/http';

class CustomEncoder {
  encodeKey(key: string): string {
    return encodeURIComponent(key);
  }

  encodeValue(value: string): string {
    return encodeURIComponent(value);
  }

  decodeKey(key: string): string {
    return decodeURIComponent(key);
  }

  decodeValue(value: string): string {
    return decodeURIComponent(value);
  }
}

export const httpClientParamsSerializer = (params: object): HttpParams =>
  new HttpParams({ encoder: new CustomEncoder(), fromObject: params as Record<string, string | number | boolean> });
