import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, catchError, map, of, tap } from 'rxjs';
import { environment } from '../../../environments/environment';
import { UserRepository } from '../../features/users/shared/repositories/user.repository';
import { UsersService } from '../../features/users/shared/services/users.service';
import { Auth } from '../../shared/types/auth.type';
import { JwtToken } from '../../shared/types/jwt-token.type';
import { User } from '../../shared/types/user.type';
import { getDecodedAccessToken } from '../../shared/utils/jwt-decode';
import { AccessTokenResponse } from '../dto/access-token-response.type';
import { LoginRequest } from '../dto/login-request.type';
import { LoginResponse } from '../dto/login-response.type';
import { AuthRepository } from '../states/auth.repository';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private readonly httpClient: HttpClient = inject(HttpClient);
  private readonly router: Router = inject(Router);
  private readonly authRepository: AuthRepository = inject(AuthRepository);
  private readonly userRepository: UserRepository = inject(UserRepository);
  private readonly usersService: UsersService = inject(UsersService);

  login(data: LoginRequest): Observable<boolean> {
    return this.httpClient.post<LoginResponse>(`${environment.apiUrl}/auth/login`, data).pipe(
      map((res: LoginResponse) => {
        const { user, ...auth } = res;
        // Sauvegarde du token
        this.authRepository.update(auth);
        if (auth.accessToken && user) {
          // Sauvegarde des données de l'utilisateur
          this.userRepository.update(user);
          return true;
        }

        return false;
      }),
    );
  }

  logout(): void {
    // On vide les données de l'utilisateur
    this.userRepository.reset();
    // Puis on supprime les données d'authentification
    this.authRepository.reset();
    this.router.navigate(['/', 'login']);
  }

  refreshToken(): Observable<Auth | false> {
    const currentRefreshToken: string | undefined = this.authRepository.auth.refreshToken;
    if (currentRefreshToken) {
      return this.httpClient
        .post<AccessTokenResponse>(`${environment.apiUrl}/auth/jwt/refresh`, { refreshToken: currentRefreshToken })
        .pipe(
          map((res: AccessTokenResponse) => {
            if (res.accessToken && res.refreshToken) {
              const { accessToken, refreshToken } = res;
              const auth: Auth = { accessToken, refreshToken };
              this.authRepository.update(auth);
              return auth;
            }
            return false;
          }),
        );
    }
    return of(false);
  }

  refreshUser(): Observable<User | null> {
    const accessToken: string | undefined = this.authRepository.auth.accessToken;
    if (accessToken) {
      const token: JwtToken | null = getDecodedAccessToken(accessToken);
      if (token?.id) {
        return this.usersService.get(token.id).pipe(
          tap((user: User) => {
            if (user.id && user.id === token.id) {
              this.userRepository.update(user);
            }
          }),
          catchError(() => {
            this.logout();
            return of(null);
          }),
        );
      }
    }

    return of(null);
  }

  // TODO : extraire le type pour un DTO
  resetPassword(data: { uid: string; email: string }): Observable<unknown> {
    return this.httpClient.post(`${environment.apiUrl}/auth/reset-password/create`, data).pipe();
  }

  // TODO : extraire le type pour un DTO
  resetPasswordWithToken(data: { password: string; token: string }): Observable<{ token: string } | null> {
    return this.httpClient
      .post(`${environment.apiUrl}/auth/reset-password/update`, data)
      .pipe(map((res: unknown) => res as { token: string }));
  }
}
