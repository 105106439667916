import { Routes } from '@angular/router';
import { FeatureAcl, FeatureAclGuard } from '../../../core/acl';

export const offersDistributedManualAmountValidSubscriptionProtectedRoutes: Routes = [
  {
    // On laisse companies/ au début pour conserver l'activation du bouton dans le menu latéral
    path: 'companies/offers/distributed-manual-amount',
    // TODO : ajouter une Guard pour vérifier qu'il s'agit bien d'une offre DistributedManualAmount
    // éventuellement via un résolveur pour récupérer l'offre et la vérifier ou overkill ?
    children: [
      {
        path: 'reports/:offerId',
        canActivate: [FeatureAclGuard(FeatureAcl.distributedManualAmountReports)],
        loadComponent: () => import('./pages/reports/reports.component'),
      },
    ],
  },
];
