import { Injectable, inject } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarRef } from '@angular/material/snack-bar';
import { SnackBarComponent } from '../snack-bar.component';
import { SnackBarStatus, SnackBarStatusType } from '../types/snack-bar-type.enum';

@Injectable()
export class SnackBarService {
  private readonly snackBar: MatSnackBar = inject(MatSnackBar);

  getName(): string {
    return 'SnackBarService';
  }

  open(message: string, action?: string, duration?: number): MatSnackBarRef<unknown> {
    return this.openSnackBar(SnackBarStatus.DEFAULT, message, action, duration);
  }

  openInfo(message: string, action?: string, duration?: number): MatSnackBarRef<unknown> {
    return this.openSnackBar(SnackBarStatus.INFO, message, action, duration);
  }

  openSuccess(message: string, action?: string, duration?: number): MatSnackBarRef<unknown> {
    return this.openSnackBar(SnackBarStatus.SUCCESS, message, action, duration);
  }

  openWarning(message: string, action?: string, duration?: number): MatSnackBarRef<unknown> {
    return this.openSnackBar(SnackBarStatus.WARNING, message, action, duration);
  }

  openError(message: string, action?: string, duration?: number): MatSnackBarRef<unknown> {
    return this.openSnackBar(SnackBarStatus.ERROR, message, action, duration);
  }

  openSnackBar(
    type: SnackBarStatusType = SnackBarStatus.DEFAULT,
    message: string,
    action?: string,
    duration = 1500,
  ): MatSnackBarRef<unknown> {
    const panelClass = type !== SnackBarStatus.DEFAULT ? `yc-${type.toLowerCase().toString()}` : '';
    const config: MatSnackBarConfig = {
      data: {
        message,
        action,
        type,
      },
      horizontalPosition: 'center',
      verticalPosition: 'top',
      duration,
      panelClass,
    };
    return this.snackBar.openFromComponent(SnackBarComponent, config);
  }
}
