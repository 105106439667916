import { Routes } from '@angular/router';
import { FeatureAcl, FeatureAclGuard } from '../../core/acl';

export const cardsFeatureRoutes: Routes = [
  {
    path: 'cards',
    canActivate: [FeatureAclGuard(FeatureAcl.cards)],
    children: [
      {
        path: '',
        pathMatch: 'full',
        loadComponent: () => import('./pages/list/list.component'),
      },
      {
        path: 'generate',
        canActivate: [FeatureAclGuard(FeatureAcl.cardGenerate)],
        loadComponent: () => import('./pages/generate/generate.component'),
      },
      {
        path: 'view/:id',
        canActivate: [FeatureAclGuard(FeatureAcl.cardView)],
        loadComponent: () => import('./pages/view/view.component'),
      },
    ],
  },
];
