import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable, map } from 'rxjs';
import { environment } from '../../../environments/environment';
import { BYPASS_GLOBAL_ERROR_INTERCEPTOR } from '../constants/bypass-global-error-interceptor.token';
import { httpClientParamsSerializer } from '../utils/http-client-params-serializer';

@Injectable({
  providedIn: 'root',
})
export abstract class ApiAbstractService<T> {
  protected readonly httpClient: HttpClient = inject(HttpClient);

  abstract endpoint: string;

  toServerModel(entity: T | Partial<T>): unknown {
    return entity;
  }

  fromServerModel(json: unknown): T {
    return json as T;
  }

  getList(params?: object, bypassGlobalError?: boolean): Observable<T[]> {
    // const params = new HttpParams()
    // 	.set('limit', index.toString())
    // 	.set('offset', page.toString());

    return this.httpClient
      .get<T[]>(this.getResourceUrl(), {
        ...this.getMergedParams(params),
        ...this.getGlobalErrorContext(bypassGlobalError),
      })
      .pipe(map((list: T[]) => list.map((item: T) => this.fromServerModel(item))));
  }

  get(id: string, params?: object, bypassGlobalError?: boolean): Observable<T> {
    return this.httpClient
      .get(this.getResourceUrl(id), {
        ...this.getMergedParams(params),
        ...this.getGlobalErrorContext(bypassGlobalError),
      })
      .pipe(map((json: unknown) => this.fromServerModel(json)));
  }

  create<K>(resource: Partial<T>, bypassGlobalError?: boolean): Observable<K> {
    return this.httpClient.post<K>(this.getResourceUrl(), this.toServerModel(resource), {
      ...this.getGlobalErrorContext(bypassGlobalError),
    });
  }

  update(id: string, resource: Partial<T>, bypassGlobalError?: boolean): Observable<unknown> {
    return this.httpClient.patch(this.getResourceUrl(id), this.toServerModel(resource), {
      ...this.getGlobalErrorContext(bypassGlobalError),
    });
  }

  delete(id: string, bypassGlobalError?: boolean): Observable<unknown> {
    return this.httpClient.delete(this.getResourceUrl(id), {
      ...this.getGlobalErrorContext(bypassGlobalError),
    });
  }

  protected getResourceUrl(id?: string): string {
    const resource = id ? `/${id}` : '';
    return `${environment.apiUrl}/${this.endpoint}${resource}`;
  }

  protected getGlobalErrorContext(bypassGlobalError?: boolean): object {
    return bypassGlobalError ? { context: new HttpContext().set(BYPASS_GLOBAL_ERROR_INTERCEPTOR, true) } : {};
  }

  protected getMergedParams(params?: object): object {
    return params ? { params: httpClientParamsSerializer(params) } : {};
  }
}
