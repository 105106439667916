import { ROLES_ACL, Role } from '../models/role.model';
import { UserRole } from '../types/user-role.type';
import { User } from '../types/user.type';

export const userIsAllowedTo = (user: User | undefined, role: Role | Role[]): boolean => {
  if (!user || !user.roles?.length) {
    return false;
  }

  const userRole: UserRole = user.roles?.at(0) || ({ role: Role.forbidden } as UserRole);
  const rolesAcl = ROLES_ACL[userRole.role];

  if (Array.isArray(role)) {
    return role.some((r: Role) => rolesAcl.includes(r));
  }

  // On vérifie si l'utilisateur dispose du rôle minimal demandé
  return rolesAcl.includes(role);
};
