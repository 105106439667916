import { isDevMode } from '@angular/core';
import { Routes } from '@angular/router';
import { FeatureAcl, FeatureAclGuard } from '../../core/acl';

// Routes Debug disponibles uniquement en mode développement
export const debugFeatureRoutes: Routes = isDevMode()
  ? [
      {
        path: 'debug',
        canActivate: [FeatureAclGuard(FeatureAcl.debug)],
        loadComponent: () => import('./pages/layout/layout.component'),
      },
    ]
  : [];
