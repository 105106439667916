import { inject } from '@angular/core';
import { CanActivateChildFn, CanActivateFn, Router, UrlTree } from '@angular/router';
import { UserRepository } from '../../../users/shared/repositories/user.repository';

export const CguAcknowledgementGuard: CanActivateFn | CanActivateChildFn = (): boolean | UrlTree => {
  const router: Router = inject(Router);
  const userRepository: UserRepository = inject(UserRepository);

  // Retourne TRUE si l'utilisateur a déjà accepté les CGU, sinon redirige vers la page d'acquittement
  return userRepository.user?.cgu || router.parseUrl('/init/cgu');
};
