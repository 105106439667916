import { Routes } from '@angular/router';
import { FeatureAcl, FeatureAclGuard } from '../../core/acl';

export const statsFeatureRoutes: Routes = [
  {
    path: 'stats',
    canActivate: [FeatureAclGuard(FeatureAcl.stats)],
    loadComponent: () => import('./pages/stats/stats.component'),
  },
];
