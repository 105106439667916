import * as dayjs from 'dayjs';
import * as timezone from 'dayjs/plugin/timezone';
import * as utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
dayjs.extend(timezone);

const DEFAULT_TIMEZONE = 'Europe/Paris';

/**
 * Normalise la date en objet dayjs
 * @param date date à normaliser
 * @returns date normalisée
 */
const normalizeDate = (date: Date | string | number | dayjs.Dayjs): dayjs.Dayjs =>
  dayjs.isDayjs(date) ? date : dayjs(date);

/**
 * Retourne la date dans le fuseau horaire par défaut
 * @param date date à convertir, sinon date du jour
 * @returns date dans le fuseau horaire par défaut
 */
export const tzDate = (date?: Date | string | number | dayjs.Dayjs): dayjs.Dayjs =>
  normalizeDate(date ?? dayjs()).tz(DEFAULT_TIMEZONE);

export const getUTCStartOfYear = (date?: Date | string | number | dayjs.Dayjs): dayjs.Dayjs =>
  tzDate(date).utc().startOf('year');

export const getStartOfDay = (date?: Date | string | number | dayjs.Dayjs): dayjs.Dayjs => getStartOfDate('day', date);
export const getStartOfMonth = (date?: Date | string | number | dayjs.Dayjs): dayjs.Dayjs =>
  getStartOfDate('month', date);

export const getStartOfYear = (date?: Date | string | number | dayjs.Dayjs): dayjs.Dayjs =>
  getStartOfDate('year', date);

export const getStartOfDate = (unit: dayjs.OpUnitType, date?: Date | string | number | dayjs.Dayjs): dayjs.Dayjs =>
  tzDate(date).startOf(unit);
