import { Injectable, inject, isDevMode } from '@angular/core';
import { Observable, map, of } from 'rxjs';
import { UserRepository } from '../../features/users/shared/repositories/user.repository';
import { Role } from '../models/role.model';
import { WINDOW } from '../tokens/window.token';
import { httpClientParamsSerializer } from '../utils/http-client-params-serializer';
import { ApiAbstractService } from './api-abstract.service';

@Injectable({
  providedIn: 'root',
})
export class StripeApiService extends ApiAbstractService<unknown> {
  override endpoint = 'stripe-api';

  private readonly window: Window = inject(WINDOW);
  private readonly userRepository: UserRepository = inject(UserRepository);

  getCustomerPortalSessionUrl(redirectUrl?: string): Observable<string | false> {
    const stripeId = this.userRepository.user?.stripeId;
    if (!stripeId) {
      return of(false);
    }

    const params = {
      stripeId,
      redirectUrl: redirectUrl ? redirectUrl : this.window.location.href,
    };

    return this.httpClient
      .get(this.getResourceUrl('customer/portal'), { params: httpClientParamsSerializer(params) })
      .pipe(map((res: unknown) => (res as { url: string })?.url || false));
  }

  checkConnectAccount(): Observable<boolean> {
    return this.httpClient.get<boolean>(this.getResourceUrl('connect/account/check'));
  }

  getConnectAccountOnboardingLinkUrl(): Observable<string | false> {
    // Redirection vers la page de timeout avant refresh pour laisser le temps de traiter les events Webhook Stripe
    const redirectUrl = `${this.window.location.origin}/stripe/refresh`;
    return this.httpClient
      .get(this.getResourceUrl('connect/account/create'), { params: httpClientParamsSerializer({ redirectUrl }) })
      .pipe(map((res: unknown) => (res as { url: string })?.url || false));
  }

  getStripeExpressDashboardUrl(): Observable<string | false> {
    return this.httpClient
      .get(this.getResourceUrl('connect/account/login_link'))
      .pipe(map((res: unknown) => (res as { url: string })?.url || false));
  }

  creditCompanyStripeAccount(companyId: string): Observable<boolean> {
    // Fonctionnalité accessible uniquement en DEV aux utilisateurs YuzuDev+
    if (!isDevMode() || !this.userRepository.isAllowedTo(Role.yuzudev)) {
      return of(false);
    }

    return this.httpClient.get<boolean>(this.getResourceUrl(`debug/credit/${companyId}`));
  }
}
