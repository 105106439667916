<span matSnackBarLabel>
  @if (icon) {
    <mat-icon>{{ icon }}</mat-icon>
  }
  {{ data.message }}
</span>
@if (data.action) {
  <span matSnackBarActions>
    <button
      mat-button
      matSnackBarAction
      (click)="snackBarRef.dismissWithAction()"
    >
      {{ data.action }}
    </button>
  </span>
}
