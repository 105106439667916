import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { NgApexchartsModule } from 'ng-apexcharts';

const ANGULAR = [RouterOutlet];
const CHARTS = [NgApexchartsModule];

@Component({
  selector: 'yc-root',
  standalone: true,
  imports: [...ANGULAR, ...CHARTS],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {}
