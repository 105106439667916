import { ChangeDetectionStrategy, Component, OnInit, inject } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';
import { SnackBarData } from './types/snack-bar-data.type';
import { SnackBarIcon } from './types/snack-bar-icon.constant';
import { SnackBarStatus } from './types/snack-bar-type.enum';

const MATERIAL = [MatIconModule];

@Component({
  selector: 'yc-snack-bar',
  standalone: true,
  imports: [...MATERIAL],
  templateUrl: './snack-bar.component.html',
  styleUrls: ['./snack-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SnackBarComponent implements OnInit {
  data: SnackBarData = inject(MAT_SNACK_BAR_DATA);
  icon: string | undefined;
  snackBarRef: MatSnackBarRef<unknown> = inject(MatSnackBarRef);

  ngOnInit(): void {
    if (this.data && this.data.type && this.data.type !== SnackBarStatus.DEFAULT) {
      this.icon = SnackBarIcon[this.data.type];
    }
  }
}
