import { inject } from '@angular/core';
import { CanActivateChildFn, CanActivateFn, Router, UrlTree } from '@angular/router';
import { catchError, map, Observable, of } from 'rxjs';
import { Role } from '../../../../shared/models/role.model';
import { StripeApiService } from '../../../../shared/services/stripe-api.service';
import { User } from '../../../../shared/types/user.type';
import { userHasRole } from '../../../../shared/utils/user-has-role.utils';
import { UserRepository } from '../repositories/user.repository';

const INVALID_STRIPE_CONNECTED_ACCOUNT_REDIRECT_URL = '/company/init/stripe';

export const ValidChildCompanyStripeAccountGuard: CanActivateFn | CanActivateChildFn = (): Observable<
  true | UrlTree
> => {
  const router: Router = inject(Router);
  const userRepository: UserRepository = inject(UserRepository);
  const user: User = userRepository.user;

  // S'il s'agit du gérant d'une enseigne fille
  if (userRepository.isActingForChildCompany && userHasRole(user, Role.admin) && !user.company.stripe?.isValidAcct) {
    // Vérification de l'état du Connect Account Stripe
    return inject(StripeApiService)
      .checkConnectAccount()
      .pipe(
        map((valid: boolean) => valid || router.parseUrl(INVALID_STRIPE_CONNECTED_ACCOUNT_REDIRECT_URL)),
        catchError(() => of(router.parseUrl(INVALID_STRIPE_CONNECTED_ACCOUNT_REDIRECT_URL))),
      );
  }

  return of(true);
};
