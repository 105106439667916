import { Routes } from '@angular/router';
import { FeatureAcl, FeatureAclGuard } from '../../core/acl';

export const usersFeatureRoutes: Routes = [
  {
    path: 'users',
    canActivate: [FeatureAclGuard(FeatureAcl.users)],
    children: [
      {
        path: '',
        pathMatch: 'full',
        loadComponent: () => import('./pages/list/list.component'),
      },
      {
        path: 'create',
        canActivate: [FeatureAclGuard(FeatureAcl.userCreate)],
        loadComponent: () => import('./pages/create/create.component'),
      },
      {
        path: 'create/:companyId',
        canActivate: [FeatureAclGuard(FeatureAcl.userCreate)],
        loadComponent: () => import('./pages/create/create.component'),
      },
      {
        path: 'view/:id',
        loadComponent: () => import('../../shared/pages/user-view/view.component'),
      },
      {
        path: 'edit/:id',
        loadComponent: () => import('../../shared/pages/user-edit/edit.component'),
      },
    ],
  },
];
