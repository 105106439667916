import { HttpErrorResponse, HttpHandlerFn, HttpInterceptorFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { catchError, of, switchMap, throwError } from 'rxjs';
import { SnackBarService } from '../../shared/components/snack-bar/services/snack-bar.service';
import { BYPASS_GLOBAL_ERROR_INTERCEPTOR } from '../../shared/constants/bypass-global-error-interceptor.token';
import { Auth } from '../../shared/types/auth.type';
import { AuthService } from '../services/auth.service';

let isRefreshingYCAccessToken = false;

export const HttpErrorInterceptor: HttpInterceptorFn = (request: HttpRequest<unknown>, next: HttpHandlerFn) => {
  const authService: AuthService = inject(AuthService);
  const snackBarService: SnackBarService = inject(SnackBarService);

  return next(request).pipe(
    catchError((error: HttpErrorResponse) => {
      // S'il s'agit d'une erreur 401 Unauthorized
      if (error instanceof HttpErrorResponse && !request.url.includes('auth/login') && error.status === 401) {
        if (!isRefreshingYCAccessToken) {
          isRefreshingYCAccessToken = true;

          // On tente de rafraîchir le token
          return authService.refreshToken().pipe(
            switchMap((auth: Auth | false) => {
              isRefreshingYCAccessToken = false;

              // Access Token correctement mis à jour
              if (auth) {
                // On relance la requête initiale avec le nouveau token
                const authRequest = request.clone({
                  setHeaders: { Authorization: `Bearer ${auth.accessToken}` },
                });
                return next(authRequest);
              }

              authService.logout();
              return of();
            }),
            catchError((refreshError: HttpErrorResponse) => {
              isRefreshingYCAccessToken = false;

              // Si le refresh token est invalide, on déconnecte l'utilisateur
              if (
                refreshError instanceof HttpErrorResponse &&
                (refreshError.status === 400 || refreshError.status === 401 || refreshError.status === 403)
              ) {
                authService.logout();
                return of();
              }

              return throwError(() => error);
            }),
          );
        }
      }

      // On vérifie si la requête a explicitement demandé à ne pas afficher la Snackbar
      if (request.context.get(BYPASS_GLOBAL_ERROR_INTERCEPTOR) !== true) {
        // Affichage d'un message fourni par l'API ou un par défaut
        const errorMessage = error.error.message ?? `${error.status} - ${error.message}`;
        snackBarService.openError(errorMessage);
      }

      return throwError(() => error);
    }),
  );
};
