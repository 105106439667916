import { Routes } from '@angular/router';

export const stripeFeatureCguProtectedRoutes: Routes = [
  {
    path: 'stripe',
    children: [
      {
        path: 'refresh',
        loadComponent: () => import('./pages/refresh/refresh.component'),
      },
    ],
  },
];
