import { inject } from '@angular/core';
import { CanActivateChildFn, CanActivateFn, Router, UrlTree } from '@angular/router';
import { Observable, catchError, map, of } from 'rxjs';
import { Role } from '../../../../shared/models/role.model';
import { User } from '../../../../shared/types/user.type';
import { userHasRole } from '../../../../shared/utils/user-has-role.utils';
import { CheckCustomerAccountResponseDto } from '../dto';
import { UserRepository } from '../repositories/user.repository';
import { UsersService } from '../services/users.service';
import { CustomerAccountStatus } from '../types';

const INVALID_STRIPE_CUSTOMER_REDIRECT_URL = '/subscriptions/create/customer';
const STRIPE_ACCOUNT_ERROR_REDIRECT_URL = '/init/account-error';

export const ValidCustomerAccountGuard: CanActivateFn | CanActivateChildFn = (): Observable<boolean | UrlTree> => {
  const router: Router = inject(Router);
  const userRepository: UserRepository = inject(UserRepository);
  const usersService: UsersService = inject(UsersService);
  const user: User = userRepository.user;

  // S'il s'agit du gérant d'une enseigne ou super-enseigne
  if (userHasRole(user, [Role.superadmin, Role.admin])) {
    // Le gérant a bien un compte client Stripe, on vérifie s'il a complété les informations
    // requises pour l'abonnement, sinon on le redirige vers la page de création
    return usersService.checkCustomerAccount().pipe(
      map(({ res }: CheckCustomerAccountResponseDto) => {
        let redirectUrl: string = '';

        switch (res) {
          // Compte client valide
          case CustomerAccountStatus.valid:
            return true;
            break;

          // Erreur : pas de compte Stripe associé (NO Stripe ACCount)
          case CustomerAccountStatus.noStripeAccount:
            redirectUrl = STRIPE_ACCOUNT_ERROR_REDIRECT_URL;
            break;

          // Erreur : compte client Stripe non conforme
          default:
            redirectUrl = INVALID_STRIPE_CUSTOMER_REDIRECT_URL;
            break;
        }

        return router.parseUrl(redirectUrl);
      }),
      catchError(() => of(router.parseUrl(INVALID_STRIPE_CUSTOMER_REDIRECT_URL))),
    );
  }

  // Dans tous les autres cas, on autorise la navigation, la Guard suivante se chargera de gérer les redirections éventuelles
  return of(true);
};
