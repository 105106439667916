import { Role } from '../models/role.model';
import { UserRole } from '../types/user-role.type';
import { User } from '../types/user.type';

export const userHasRole = (user: User | undefined, role: Role | Role[]): boolean => {
  if (!user || !user.roles?.length) {
    return false;
  }

  if (Array.isArray(role)) {
    return user.roles?.some((r: UserRole) => role.includes(r.role));
  }

  // On vérifie si l'utilisateur dispose du rôle minimal demandé
  return user.roles?.some((r: UserRole) => r.role === role);
};
