import { Role } from '../../../shared/models/role.model';
import { FeatureAclType } from '../types';

/**
 * ACL des fonctionnalités interdites selon le rôle de l'utilisateur
 * Rôles STRICTS, donc lister tous ceux interdits parmi la liste retournée par FEATURES_ALLOWED_ACL !!
 * (blacklist de rôles spécifiques dans la "range" de la pyramide de rôles hérités)
 */
export const FEATURES_FORBIDDEN_ACL: Record<FeatureAclType, Role[]> = {
  cards: [Role.yuzucom],
  cardGenerate: [Role.yuzucom],
  cardView: [Role.yuzucom],
  companies: [],
  company: [],
  companyStripeAcct: [Role.yuzucom],
  invoices: [Role.yuzucom],
  shops: [Role.yuzucom],
  managers: [],
  customers: [Role.yuzucom],
  users: [],
  userCreate: [Role.yuzucom],
  stats: [],
  dashboard: [],
  subscriptions: [],
  subscription: [Role.yuzucom],
  subscriptionCreate: [Role.yuzucom],
  subscriptionRenew: [Role.yuzucom],
  mailing: [Role.yuzucom, Role.superadmin],
  debug: [],
  // ACL spécifiques aux offres
  distributedManualAmountReports: [Role.yuzucom],
};
