import { Routes } from '@angular/router';
import { offersDistributedManualAmountValidSubscriptionProtectedRoutes } from './distributed-manual-amount/distributed-manual-amount.routes';

export const offersPublicRoutes: Routes = [];
export const offersProtectedRoutes: Routes = [];
export const offersCguProtectedRoutes: Routes = [];
export const offersValidStripeCustomerRoutes: Routes = [];
export const offersValidSubscriptionProtectedRoutes: Routes = [
  ...offersDistributedManualAmountValidSubscriptionProtectedRoutes,
];
