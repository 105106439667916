import { Routes } from '@angular/router';
import { FeatureAcl, FeatureAclGuard } from '../../core/acl';

export const invoicesFeatureRoutes: Routes = [
  {
    path: 'invoices',
    canActivate: [FeatureAclGuard(FeatureAcl.invoices)],
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'list',
      },
      {
        path: 'list',
        loadComponent: () => import('./pages/list/list.component'),
      },
    ],
  },
];
