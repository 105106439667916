export const environment = {
  apiUrl: 'https://admin.staging.yuclub.fr/api',
  testToken: '0a724fe2-bdb5-4b33-9423-02d314ca6954',
  // E-mail de contact
  contactMail: 'contact@yuclub.fr',
  // TODO : à supprimer
  // Test token utilisé en payload sur l'endpoint /init afin d'initialiser la base mongo DEV
  apiTestToken: '27b8d1d3-9b91-4ea7-8656-05bcbfc41aec',
  // ID de l'enseigne Yuzu
  yuzuCompanyId: '64fed07e9dfedf382ec4ba11',
};
