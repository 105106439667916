export enum Role {
  // Internal
  owner = 'owner',
  forbidden = 'forbidden',
  // Yuzu
  yuzu = 'yuzu',
  yuzudev = 'yuzudev',
  // Yuzu - Agents commerciaux
  yuzucom = 'yuzucom',
  // Métier
  superadmin = 'superadmin',
  admin = 'admin',
  manager = 'manager',
  seller = 'seller',
  customer = 'customer',
}

// TODO : dupliqué dans yuclub-back et yuclub-pwa, à mutualiser
// Rôles autorisés pour chaque rôle
const ACL_CUSTOMER: Role[] = [Role.customer];
const ACL_SELLER: Role[] = [...ACL_CUSTOMER, Role.seller];
const ACL_MANAGER: Role[] = [...ACL_SELLER, Role.manager];
const ACL_ADMIN: Role[] = [...ACL_MANAGER, Role.admin];
const ACL_SUPERADMIN: Role[] = [...ACL_ADMIN, Role.superadmin];
const ACL_YUZUCOM: Role[] = [...ACL_SUPERADMIN, Role.yuzucom];
const ACL_YUZUDEV: Role[] = [...ACL_YUZUCOM, Role.yuzudev];
const ACL_YUZU: Role[] = [...ACL_YUZUDEV, Role.yuzu];

export const ROLES_ACL: Record<Role, Role[]> = {
  owner: [Role.owner],
  forbidden: [Role.forbidden],
  yuzu: [...ACL_YUZU],
  yuzudev: [...ACL_YUZUDEV],
  yuzucom: [...ACL_YUZUCOM],
  superadmin: [...ACL_SUPERADMIN],
  admin: [...ACL_ADMIN],
  manager: [...ACL_MANAGER],
  seller: [...ACL_SELLER],
  customer: [...ACL_CUSTOMER],
};
